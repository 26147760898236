<template>
  <div class="main-page">
    <el-form
      size=""
      :model="ActivityForm"
      label-position="right"
      label-width="90px"
      :rules="rules"
      ref="ActivityForm"
      class="form small-form"
      @submit.native.prevent
    >
      <div class="modular">
        <div class="modular-content">
          <!-- 活动名称 -->
          <el-form-item label="活动名称：" prop="name">
            <el-input
              style="width: 400px"
              type="text"
              v-model="ActivityForm.name"
              placeholder="请输入活动名称"
            ></el-input>
          </el-form-item>
          <!-- 活动类型 -->
          <el-form-item label="活动类型：" prop="type">
            <el-radio v-model="ActivityForm.type" :label="1">线下活动</el-radio>

            <el-radio v-model="ActivityForm.type" :label="0">线上活动</el-radio>
          </el-form-item>
          <!-- 活动时间 -->
          <el-form-item label="活动时间：" prop="activityTime">
            <el-date-picker
              v-model="ActivityForm.activityTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="timestamp"
              format="yyyy-MM-dd HH:mm"
              :picker-options="pickerActivityOptions"
              @change="handleSelectTime"
              :time-arrow-control="true"
            >
            </el-date-picker>
          </el-form-item>
          <!-- 活动地址 -->
          <el-form-item
            v-if="ActivityForm.type"
            label="活动地址："
            prop="addresses"
          >
            <ActivityAddress
              :activityTime="ActivityForm.activityTime"
              :activity_end_time="ActivityForm.activity_end_time"
              :activity_start_time="ActivityForm.activity_start_time"
              :type="ActivityForm.type"
              v-model="ActivityForm.addresses"
            ></ActivityAddress>
          </el-form-item>
          <!-- 报名时间 -->
          <el-form-item
            v-if="ActivityForm.type"
            label="报名时间："
            prop="activityJoinTime"
          >
            <el-date-picker
              v-model="ActivityForm.activityJoinTime"
              type="datetimerange"
              range-separator="至"
              :picker-options="pickerJoinOptions"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="timestamp"
              @change="handleSelectJoinTime"
              time-arrow-control
            >
            </el-date-picker>
            <div class="tips">
              <i class="el-icon-warning-outline"></i>
              不设置报名时间则活动开始前都可报名
            </div>
          </el-form-item>
          <!-- 报名人数 -->
          <el-form-item
            v-if="ActivityForm.type"
            label="报名人数："
            prop="join_limit"
          >
            <el-input-number
              placeholder="请填写报名人数"
              v-model.number="ActivityForm.join_limit"
              :step="1"
            ></el-input-number>
            <div class="tips">
              <i class="el-icon-warning-outline"></i>
              0 为不限制报名人数
            </div>
          </el-form-item>

          <el-form-item label="活动直播：" prop="jump_config">
            <el-radio
              v-if="ActivityForm.type"
              v-model="ActivityForm.is_live"
              :label="1"
              >开启</el-radio
            >
            <el-radio
              v-if="ActivityForm.type"
              v-model="ActivityForm.is_live"
              :label="0"
              >关闭</el-radio
            >
            <template v-if="ActivityForm.is_live || !ActivityForm.type">
              <div class="live-box">
                <div class="live-config">
                  <el-form-item
                    label="直播方式："
                    label-width="100px"
                    prop="jump_type"
                  >
                    <el-radio-group
                      v-model="ActivityForm.jump_type"
                      @change="handleJumpTypeChange"
                    >
                      <el-radio label="flow">拉流地址</el-radio>

                      <el-radio label="wxlive">微信小程序直播</el-radio>
                      <el-radio label="path">第三方微信小程序</el-radio>
                      <el-radio label="qqmeeting">腾讯会议</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form
                    size="small"
                    :model="ActivityForm.jump_config"
                    :rules="JumpConfigRules"
                    ref="JumpConfig"
                    label-position="right"
                    label-width="100px"
                  >
                    <el-form-item
                      label="拉流地址："
                      prop="url"
                      v-if="ActivityForm.jump_type === 'flow'"
                    >
                      <el-input
                        type="text"
                        v-model="ActivityForm.jump_config.url"
                      ></el-input>
                    </el-form-item>

                    <el-form-item
                      label="小程序名称："
                      prop="name"
                      v-if="ActivityForm.jump_type === 'path'"
                    >
                      <el-input
                        type="text"
                        v-model="ActivityForm.jump_config.name"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="AppID："
                      prop="appid"
                      v-if="ActivityForm.jump_type === 'path'"
                    >
                      <el-input
                        type="text"
                        v-model="ActivityForm.jump_config.appid"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="页面链接："
                      prop="path"
                      v-if="ActivityForm.jump_type === 'path'"
                    >
                      <el-input
                        type="text"
                        v-model="ActivityForm.jump_config.path"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="选择数据："
                      prop="roomId"
                      v-if="ActivityForm.jump_type === 'wxlive'"
                    >
                      <el-select
                        v-model="ActivityForm.jump_config.roomId"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in wxLiveOptions"
                          :key="index"
                          :label="item.name"
                          :value="item.room_id"
                        ></el-option>
                      </el-select>
                      <p class="info">
                        <i class="el-icon-info" />{{
                          liveTip[ActivityForm.jump_type]
                        }}
                      </p>
                    </el-form-item>
                    <!-- 腾讯会议 -->
                    <template v-if="ActivityForm.jump_type === 'qqmeeting'">
                      <el-form-item label="会议号：" prop="code">
                        <el-input
                          type="text"
                          v-model="ActivityForm.jump_config.code"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-form>
                </div>
              </div>
            </template>
          </el-form-item>
          <!-- 选择分类 -->
          <el-form-item label="选择分类：" prop="category_ids">
            <div class="flex">
              <el-select
                v-model="ActivityForm.category_ids"
                multiple
                collapse-tags
                placeholder="请选择分类"
              >
                <el-option
                  v-for="item in categoryOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <!-- 通用添加分类组件 -->
              <AddCategoryButton
                :style="{
                  marginLeft: '12px',
                }"
                :updateList="getCategoryOptions"
                :getApi="getApi"
              >
              </AddCategoryButton>
            </div>
          </el-form-item>
          <!-- 选择架构 -->
          <el-form-item
            v-if="isCycOrganization"
            label="举办架构："
            prop="organization_id"
          >
            <el-cascader
              v-model="ActivityForm.organization_id"
              :options="
                selectPositionOptions ? isDisabled(selectPositionOptions) : []
              "
              popper-class="organization-id-cascader"
              :props="{
                checkStrictly: true,
                expandTrigger: 'hover',
                label: 'name',
                value: 'id',
                emitPath: false,
              }"
              filterable
              @change="handleExportCatalogChange"
              ref="CascaderRef"
            >
            </el-cascader>
          </el-form-item>
          <!-- 活动简介 -->
          <el-form-item
            label="活动简介："
            prop="description"
            style="max-width: 540px"
          >
            <tinymce
              ref="editor"
              @choose="handleToolBarClick"
              v-model="ActivityForm.description"
              show-article-import
            ></tinymce>
          </el-form-item>

          <!-- 活动封面图 -->
          <el-form-item label="详情图：" prop="images">
            <MediaWall
              v-model="ActivityForm.images"
              :width="194"
              :height="135"
              ratio="900:675"
              :show-cut="true"
              :draggable="true"
              :limit="imagesLimit - ActivityForm.images.length"
            >
              <p slot="info" class="img-tips" style="width: 194px">
                建议上传尺寸比例为900*506
              </p>
            </MediaWall>
            <el-input v-model="ActivityForm.images.join(',')" v-show="false" />
          </el-form-item>

          <!-- 视频上传 -->
          <el-form-item label="活动视频：" prop="video">
            <single-media-wall
              :isButtonStyle="true"
              upload-type="video"
              v-model="ActivityForm.video"
              addText="上传视频"
              :width="194"
              :height="135"
            >
              <p slot="info" class="img-tips">上传后将覆盖"详情图"的数据</p>
            </single-media-wall>
          </el-form-item>
          <!-- 视频封面图 -->
          <el-form-item
            label="视频封面图："
            prop="video_cover"
            v-if="ActivityForm.video && ActivityForm.video.url"
          >
            <SingleMediaWall
              v-model="ActivityForm.video.cover"
              :width="194"
              :height="135"
              ratio="750:532"
              :show-cut="true"
            >
              <p slot="info" class="img-tips">
                建议上传尺寸比例为900*506
                <!--                ，上传后将覆盖"详情图"的数据-->
              </p>
            </SingleMediaWall>
          </el-form-item>
          <!-- 文件上传 -->
          <el-form-item
            label="文件上传："
            prop="files"
            style="max-width: 440px"
          >
            <el-upload
              action="#"
              :before-remove="beforeRemove"
              :on-remove="handleRemove"
              :http-request="handleUpload"
              :before-upload="beforeUpload"
              multiple
              :file-list="ActivityForm.files"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传word、excel、pdf、jpg、png文件，且不超过150M
              </div>
            </el-upload>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <AddressSelector
      v-model="openAddressSelector"
      @change="handleChangeAddress"
      :detailForm="addressForm"
    />
    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages" />
    <media-selector
      v-model="showVideoList"
      type="video"
      multiple
      @select="updateVideos"
    />
    <media-selector
      v-model="showAudioList"
      type="audio"
      multiple
      @select="updateAudios"
    />
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail" />
    <!-- 导入HTML -->
    <import-html-code v-model="isShowHtmlInput" />
  </div>
</template>

<script>
import {
  ParentList,
  wxLiveList,
  getCategory,
  getOrganizationsTree,
} from '../../api/activity-list/detail'
import { saveCategory } from '../../api/cyc-category-list'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import MediaWall from '@/modules/common/components/MediaWall.vue'
import ActivityStage from '../../components/ActivityList/ActivityStage.vue'
// 地址选择
import ActivityAddress from '../../components/ActivityList/ActivityAddress.vue'
import ActivityJoinForms from '../../components/ActivityList/ActivityJoinForms/ActivityJoinForms.vue'
import AddressSelector from '../../../common/components/AddressSelector.vue'
import AddCategoryButton from '@/base/components/Base/AddCategoryButton.vue'

// 富文本相关组件
import Tinymce from '@/base/components/Editor/Tinymce'

import MediaSelector from '../../../common/components/MediaSelector'
import ImportArticle from '@/base/components/Editor/ImportArticle'
import ImportHtmlCode from '@/base/components/Editor/ImportHtmlCode'
import { getFileType } from '../../../../base/utils/tool'
import filesUpload from '../../../../base/utils/upload4'
export default {
  model: {
    prop: 'form',
    event: 'updateForm',
  },
  props: [
    'form',
    'isNext',
    'positionOptions',
    'saveDetail',
    'saveLoading',
    'isShowRules',
  ],
  data() {
    return {
      ParentList: [],
      wxLiveOptions: [],
      // 活动分类
      categoryOptions: [],
      payTypeOptions: [
        { label: '金额(元)', id: 0 },
        { label: '积分', id: 1 },
      ],
      limited_addresses: [],
      CityDataList: [],
      optionProp: {
        expandTrigger: 'hover',
        multiple: true,
        emitPath: false,
      },
      // 架构层级
      selectPositionOptions: [],
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        // category_ids: [
        //   { required: true, message: '请选择分类', trigger: 'blur' },
        // ],
        // organization_id: [
        //   { required: true, message: '请选择架构', trigger: 'change' },
        // ],
        join_price: [
          { type: 'number', message: '请输入报名费用', trigger: 'blur' },
        ],
        seat_price: [
          { type: 'number', message: '请输入报名费用', trigger: 'blur' },
        ],
        // join_positions: [
        //   { required: true, message: '请选择职务', trigger: 'change' },
        // ],
        // join_limit: [
        //   { required: true, message: '请输入报名人数', trigger: 'blur' },
        //   {
        //     type: 'number',
        //     min: 0,
        //     message: '报名人数必须大于等于0',
        //     trigger: 'blur',
        //   },
        // ],
        // activityJoinTime: [
        //   { required: true, message: '请选择报名时间', trigger: 'change' },
        // ],
        activityTime: [
          { required: true, message: '请选择活动时间', trigger: 'blur' },
        ],
        addresses: [
          { required: true, message: '请选择活动地址', trigger: 'change' },
        ],
        // description: [
        //   { required: true, message: '请输入活动简介', trigger: 'blur' },
        // ],
        // live_image: [
        //   { required: true, validator: this.LiveImgPass, trigger: "change" },
        // ],
        // number_limit: [
        //   { type: "number", message: "请输入字数限制", trigger: "blur" },
        // ],
        cover: [
          { required: true, validator: this.CoverPass, trigger: 'change' },
        ],
        images: [
          { required: true, validator: this.ImgsPass, trigger: 'change' },
        ],
        jump_config: [
          {
            validator: this.JumpConfigPass,
            trigger: 'change',
          },
        ],
      },
      JumpConfigRules: {
        url: [{ required: true, message: '请输入地址链接', trigger: 'blur' }],
        path: [{ required: true, message: '请输入页面链接', trigger: 'blur' }],
        appid: [{ required: true, message: '请输入AppID', trigger: 'blur' }],
        roomId: [
          {
            required: true,
            message: '请选择微信小程序直播数据',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入会议号',
            trigger: 'blur',
          },
        ],
        name: [
          { required: true, message: '请输入小程序名称', trigger: 'blur' },
        ],
      },
      selectablePositions: [],
      props: {
        multiple: true,
        label: 'name',
        value: 'id',
        children: 'position',
        emitPath: false,
        expandTrigger: 'hover',
      },
      // 活动时间选择范围设置
      pickerActivityOptions: {
        disabledDate: (time) => {
          return this.selectableDate(time)
        },
      },
      // 报名时间选择范围设置
      pickerJoinOptions: {
        disabledDate: (time) => {
          return this.selectableJoinDate(time)
        },
      },
      // 直播方式文案提示
      liveTip: {
        flow: '在界面上直接观看',
        path: '跳转至微信小程序界面观看',
        wxlive: '跳转至微信小程序直播间观看',
      },
      imagesLimit: 9, // 详情图限制选择数量
      openAddressSelector: false,
      // 活动地址信息
      addressForm: {
        lat: '',
        lng: '',
        address: '',
      },
      // 媒体库图片弹窗
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      isShowImportDialog: false,
      isShowHtmlInput: false,
    }
  },
  watch: {
    positionOptions: {
      handler() {
        this.positionSelected(this.ActivityForm.join_positions)
      },
      immediate: true,
    },

    'ActivityForm.type'(val) {
      if (val) {
        // 线下，直播是可选的
        this.ActivityForm.is_live = 0
      } else {
        // 线上，直播默认开启且不可修改哦
        this.ActivityForm.is_live = 1
      }
    },
  },
  created() {
    this.getWxLiveList()
    // 获取分类配置
    this.getCategoryOptions()
    // 获取架构列表
    this.getPositionOptions()
  },
  methods: {
    isDisabled(list) {
      return list.map((item) => {
        return {
          ...item,
          disabled: item.disabled === 1,
          children: item.children ? this.isDisabled(item.children) : null,
        }
      })
    },
    // 添加分类通用
    getApi(data) {
      return saveCategory(data)
    },
    /**
     * 切换直播类型时，清除验证状态
     */
    handleJumpTypeChange() {
      this.$refs['JumpConfig'].clearValidate()
      this.$refs['ActivityForm'].clearValidate('jump_config')
    },
    /**
     * 直播观看权限
     * 游客可见，即非会员必须可见
     * @param {number} value  游客是否可见 选项：0-不可见，1-可见
     */
    handleLiveAccessChange(value) {
      if (value) this.ActivityForm.live_for_user = 1
    },
    // 获取所属组织列表
    getParentList() {
      ParentList({ is_show_no_parent: 1, is_show_all_brand: 1 }).then((res) => {
        this.ParentList = res.data
      })
    },
    // 获取微信小程序直播列表
    getWxLiveList() {
      wxLiveList()
        .then((res) => {
          this.wxLiveOptions = res.data
          console.log(res.data, 'res.data')
        })
        .catch((err) => {})
    },
    // 获取活动分类
    getCategoryOptions() {
      getCategory()
        .then((res) => {
          this.categoryOptions = res.data

          // 新增的话默认选择第一个
          if (!this.form.id) {
            this.ActivityForm.category_ids = [res.data[0].id]
            console.log(res.data)
          }
        })
        .catch((err) => {})
    },

    // 获取架构
    getPositionOptions() {
      getOrganizationsTree({ is_all: 1, is_show_none: 0, hashid_enable: 1 })
        .then((res) => {
          this.selectPositionOptions = res.data
          // 新增的话默认选择第一个
          if (!this.form.id) {
            this.ActivityForm.organization_id = res.data[0].id
          }
        })
        .catch((err) => {})
    },
    // 选择报名要求
    positionSelected(e = null) {
      let positions = []
      this.positionOptions.forEach((item1) => {
        item1.position.forEach((item2) => {
          if (e && e.includes(item2.id)) {
            let position_text = item1.name + '/' + item2.name
            positions.push({ id: item2.id, position_name: position_text })
          }
          if (!e || !e.length) {
            let position_text = item1.name + '/' + item2.name
            positions.push({ id: item2.id, position_name: position_text })
          }
        })
      })
      this.selectablePositions = positions
    },
    // 阶段性报名可选择职务处理
    changeSelectablePositions(positionIds) {
      let positionTextArr = []
      this.ActivityForm.stage_json.forEach((el, i) => {
        positionTextArr = this.selectablePositions
          .filter((item) => el.position.includes(item.id))
          .map((item) => item.position_name)
        el.position_text = positionTextArr.join('、')
        el.position = el.position.filter((item) => positionIds.includes(item))
      })
      this.ActivityForm.stage_json = this.ActivityForm.stage_json.filter(
        (el) => el.position.length !== 0
      )
    },
    // 选择报名时间
    handleSelectJoinTime(value) {
      if (value && value.length) {
        this.ActivityForm.join_start_time = value[0] / 1000
        this.ActivityForm.join_end_time = value[1] / 1000
      } else {
        this.ActivityForm.join_start_time = this.ActivityForm.join_end_time = ''
      }
    },
    // 设置活动时间选择范围
    selectableDate(time) {
      if (this.ActivityForm.join_end_time && this.ActivityForm.type) {
        return time.getTime() < this.ActivityForm.join_end_time * 1000
      } else {
        return false
      }
    },
    // 报名时间选择范围设置
    selectableJoinDate(time) {
      if (this.ActivityForm.activity_start_time && this.ActivityForm.type) {
        return time.getTime() > this.ActivityForm.activity_start_time * 1000
      } else {
        return false
      }
    },
    // 选择活动时间
    handleSelectTime(value) {
      if (value && value.length) {
        this.ActivityForm.activity_start_time = value[0] / 1000
        this.ActivityForm.activity_end_time = value[1] / 1000
      } else {
        this.ActivityForm.activity_start_time = ''
        this.ActivityForm.activity_end_time = ''
      }
    },
    //选择活动地址
    handleAddressSelect() {
      this.addressForm = {
        lat: this.ActivityForm.lat,
        lng: this.ActivityForm.lng,
        address: this.ActivityForm.address,
      }
      this.openAddressSelector = true
    },
    handleChangeAddress(value) {
      let addressObj = {}
      addressObj.lat = value.lat
      addressObj.lng = value.lng
      addressObj.address = value.address
      this.ActivityForm.address.push(addressObj)
    },
    // 直播信息校验
    JumpConfigPass(rule, value, callback) {
      if (!this.ActivityForm.is_live) {
        callback()
        return
      }
      if (value && this.$refs['JumpConfig']) {
        this.$refs['JumpConfig'].validate((valid) => {
          if (valid) {
            callback()
          } else {
            callback(new Error(' '))
          }
        })
      } else {
        callback(new Error('表单格式错误'))
      }
    },
    // 报名表单校验
    // joinFormPass(rule, value, callback) {
    //   if (value.length || this.ActivityForm.join_form_enable === 0) {
    //     callback();
    //   } else {
    //     callback(new Error("报名表单不能为空"));
    //   }
    // },
    //直播封面图校验
    LiveImgPass(rule, value, callback) {
      if (value) {
        callback()
      } else {
        callback(new Error('请上传直播封面图'))
      }
    },
    // 活动封面图校验
    CoverPass(rule, value, callback) {
      if (value) {
        callback()
      } else {
        callback(new Error('请上传活动封面图'))
      }
    },
    // 活动图校验
    ImgsPass(rule, value, callback) {
      if (value.length && value) {
        callback()
      } else {
        callback(new Error('请上传活动图'))
      }
    },
    // 下一步
    goNext(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.is_next = true
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              console.log('isError', isError)
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    // 保存
    formSubmit(formName) {
      let flag = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          flag = true
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          flag = false
        }
      })
      console.log(flag, 'flag')
      return flag
    },
    // 取消
    cancelConfig() {
      this.$router.push({ name: 'ActivityList' })
    },
    // 关闭架构折叠面板
    handleExportCatalogChange() {
      this.$refs.CascaderRef.dropDownVisible = false
    },
    // 富文本点击事件
    handleToolBarClick(e) {
      switch (e.type) {
        case 'image':
          this.showImageList = true
          break
        case 'video':
          this.showVideoList = true
          break
        case 'audio':
          this.showAudioList = true
          break
        case 'import':
          this.isShowImportDialog = true
          break
        case 'html':
          this.isShowHtmlInput = true
          break
      }
    },
    // ------富文本相关
    updateImages(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<img src="${item.url}"/>`
        )
      })
    },
    updateVideos(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
          // `<video poster="${item.config.thumbnail}" controls src="${item.url}"></video>`
        )
      })
    },
    updateAudios(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        )
      })
    },
    // 导入公众号文章内容
    getImportDetail(e) {
      // e: {account, content, cover_image, title}

      const newContent = this.form.description + '<p><br/></p>' + e.content
      this.$set(this.form, 'description', newContent)
      this.$refs.editor.$emit('change', newContent)

      if (!this.form.name) this.form.name = e.title
      if (!this.form.cover) this.form.cover = e.cover_image
      if (this.form.images.length < 9) {
        this.form.images.push(e.cover_image)
      }
    },
    beforeRemove(file) {
      /* 不符合条件的文件无法上传，选择后会触发 beforeRemove 和 handleRemove */
      if (!this.beforeUpload(file, true)) return
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleRemove(file, fileList) {
      this.$set(this.form, 'files', fileList)
    },
    /**
     * @param file
     * @param silent  是否关闭错误提示
     * */
    beforeUpload(file, silent = false) {
      /* 文件类型判断 */
      const myFileType = getFileType(file?.name)
      const fileTypeSupported = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'jpg',
        'jpeg',
        'png',
      ].includes(myFileType)
      // 大小判断
      const is10M = file.size / 1024 / 1024 < 150
      if (!fileTypeSupported && !silent) {
        this.$message.error('上传文件类型仅支持 word、excel、pdf、jpg、png !')
      } else if (!is10M && !silent) {
        // 大小不匹配
        this.$message.error('上传文件大小不能超过 150MB !')
      }
      // 返回 false 阻断 true 正常上传
      return is10M && fileTypeSupported
    },
    handleUpload(file) {
      /* 上传成功后才将{name: '', url: ''}push到fileList中，会有成功图标 */
      // this.fileList.push({name: file.file.name, url: ''})
      filesUpload({
        configApi: '/admin/admin/media/requestUpload',
        data: file.file,
        progress: (n) => {
          // 更新 el-upload file-list 自带的进度条数值
          file.onProgress({ percent: n })
        },
      })
        .then((res) => {
          this.ActivityForm.files.push({ ...res.data, name: file.file.name })
          // this.$set(this.form, files, [...this.ActivityForm.files, {...res.data, name: file.file.name}])
        })
        .catch(() => {})
    },
  },
  computed: {
    ActivityForm: {
      get() {
        return this.form
      },
      set(val) {
        this.$emit('updateForm', val)
      },
    },
    is_next: {
      get() {
        return this.isNext
      },
      set(val) {
        this.$emit('update:next', val)
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
    isCycOrganization() {
      return this.$store.getters.tenant.features.includes('cyc_organization')
    },
  },
  components: {
    SingleMediaWall,
    AddressSelector,
    MediaWall,
    ActivityStage,
    ActivityJoinForms,
    MediaSelector,
    ImportArticle,
    ImportHtmlCode,
    Tinymce,
    ActivityAddress,
    AddCategoryButton,
  },
}
</script>

<style lang="scss" scoped>
.main-page {
  .tips {
    // text-align: center;
    font-size: 12px;
    color: #c0c4cc;
    line-height: 24px;
    margin-top: 6px;
  }
  .join-type {
    .el-form-item + .el-form-item {
      margin-top: 16px;
    }
  }

  .bmView {
    width: 400px;
    height: 300px;
  }

  .address-detail {
    margin-left: 5px;
    font-size: 12px;
    color: #3d6dcc;
    font-weight: normal;
    text-decoration: none;
  }
  .live-box {
    padding: 16px;
    background-color: #f5f7fa;
    border-radius: 4px;
    margin-top: 5px;
    width: 700px;

    .live-config {
      background-color: #fff;
      padding: 10px 20px;
      border-radius: 4px;

      .el-form-item {
        margin-bottom: 15px;
      }
    }
  }

  .el-input {
    ::v-deep.el-input-group__append {
      background-color: #fff;
    }
  }

  .info-title {
    position: absolute;
    top: -10px;
    display: flex;
    span {
      max-width: 120px;
      color: #cc5522;
      font-size: 14px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .info-content {
    position: absolute;
    top: 20px;

    p {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .search-address {
    width: 400px;
    text-align: center;
    padding: 10px 0 0;
    font-size: 12px;
    font-weight: 500;
    color: rgba(117, 117, 117, 1);
  }

  .img-tips {
    text-align: center;
    font-size: 12px;
    color: #c0c4cc;
    line-height: 24px;
  }

  .live-image {
    margin-top: 20px;
    margin-bottom: 0;
  }
}
.modular-content {
  margin-left: 11px;
}
.company-address {
  padding: 10px 15px;
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.join_remark_tips {
  margin-top: 15px;
  ::v-deep .el-textarea {
    padding-left: 15px;
  }
  .info {
    padding-left: 15px;
  }
}
::v-deep .small-form .el-textarea textarea {
  height: 200px;
}
</style>

<template>
  <div class="content-box">
    <el-dialog
      title="会场布置图"
      :visible="is_show"
      width="600px"
      class="dialog-vertical"
      @close="close()"
      @open="openDialogFn"
    >
      <el-form
        v-loading="loading"
        size="medium"
        class="medium-form"
        :model="form"
        :rules="rules"
        label-width="65px"
        ref="form"
        @submit.native.prevent="() => {}"
      >
        <el-form-item label="会场：" prop="placeList">
          <el-scrollbar v-if="form.placeList.length" style="height: 330px; width: 100%; margin-bottom: 10px">
            <div class="place-box" >
              <el-card
                class="item-card"
                v-for="(item, i) in form.placeList"
                :key="item.id"
              >
                <div slot="header" class="place-header">
                  <div class="title">会场{{ i + 1 }}</div>
                  <div class="control">
                    <el-button type="text" @click="editRow(item)"
                      >编辑</el-button
                    >
                    <el-button type="text" @click="deleteRow(item.id)"
                      >删除</el-button
                    >
                  </div>
                </div>
                <div class="label">会场名称：{{ item.name }}</div>
                <div class="label">会场地址：{{ item.address }}</div>
                <div style="display: flex">
                  会场布置图：
                  <el-image
                    style="width: 194px; height: 135px"
                    :src="item.image"
                    :preview-src-list="[item.image]"
                    fit="contain"
                  >
                  </el-image>
                </div>
              </el-card>
            </div>
          </el-scrollbar>
          <el-button type="primary" @click="addPlace">添加地址</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="small"
          @click="submit()"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <SavePlaceDialog
      @update="getList"
      v-model="showSavePlace"
      :currentItem="currentItem"
      @changeItem="changeItem"
    ></SavePlaceDialog>
  </div>
</template>
<script>
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";
import { placeList, delPlace } from "../../api/activity-ranking/list";
import SavePlaceDialog from "./SavePlaceDialog.vue";

export default {
  name: "EntranceDialog",
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      detault: false,
    },
  },
  components: { SingleMediaWall, SavePlaceDialog },
  created() {},
  computed: {
    // 显示框
    is_show: {
      get() {
        return this.$props.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
  watch: {},

  data() {
    return {
      form: {
        placeList: [],
      },
      rules: {},
      loading: false,
      // 当前操作的placeList item
      currentItem: {
        id: "",
        name: "",
        address: "",
        lng: "",
        lat: "",
        image: "",
      },
      showSavePlace: false,
    };
  },
  methods: {
    close() {
      this.is_show = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.close();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      this.close();
    },
    openDialogFn() {
      this.getList();
    },
    getList() {
      this.loading = true;
      placeList({ activity_id: this.$route.params.id })
        .then((res) => {
          this.form.placeList = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 添加空的会场数据
    addPlace() {
      this.currentItem = {
        id: 0,
        activity_id: this.$route.params.id,
        name: "",
        address: "",
        lng: "",
        lat: "",
        image: "",
      };
      this.showSavePlace = true;
    },
    changeItem(item) {
      this.currentItem = item;
    },
    editRow(item) {
      this.currentItem = item;
      this.showSavePlace = true;
    },
    deleteRow(id) {
      this.loading = true;
      delPlace({ id })
        .then((res) => {
          this.$message.success(res.msg);
          this.getList()
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.img-tips {
  margin-top: 10px;
  font-size: 12px;
  color: #b0b0b0;
}
.place-box {
  padding: 10px;
  background-color: #f2f3f5;
  .item-card {
    background-color: #fff;
    border-radius: 10px;
    .place-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .item-card + .item-card {
    margin: 10px 0;
  }
}

::v-deep .el-card__header {
  padding: 5px 20px;
}
</style>

import api from "@/base/utils/request";

// 创建编辑活动
export const saveAll = data => {
  return api({
    url: "/admin/cyc_activity/activity/saveAll",
    method: "post",
    data
  });
};
// 创建编辑活动
export const publish = data => {
  return api({
    url: "/admin/cyc_activity/activity/publish",
    method: "post",
    data
  });
};
//撤销发布
export const withdraw = data => {
  return api({
    url: "/admin/cyc_activity/activity/withdraw",
    method: "post",
    data
  });
};
// 详情
export const activityDetail = data => {
  return api({
    url: "/admin/cyc_activity/activity/detail2",
    method: "post",
    data
  });
};
// 获取架构职务
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data,
    notCancel: true

  });
};
// 获取海报详情
export const getPosterBgs = data => {
  return api({
    url: "/admin/cyc_activity/activity/getPosterBgs",
    method: "post",
    data,
    notCancel: true

  });
};
<template>
  <!-- 仅点击 -->
  <div class="category-button">
    <el-button type="primary" @click="addCategory">添加分类</el-button>

    <el-dialog
      title="添加分类"
      :visible.sync="openDialog"
      append-to-body
      width="500px"
      :show-close="true"
      class="dialog-vertical"
      :z-index="99999"
      @close="cancel"
    >
      <el-form
        size="medium"
        :model="CategoryData"
        ref="CategoryName"
        label-width="95px"
        @submit.native.prevent
      >
        <slot :CategoryData="CategoryData"> </slot>
        <el-form-item
          label="分类名称："
          prop="name"
          :rules="{
            required: true,
            message: '分类名称不能为空',
            trigger: 'blur',
          }"
        >
          <el-input
            type="text"
            style="width: 75%"
            v-model="CategoryData.name"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="submit" :loading="saveLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AddButton',
  props: {
    getApi: {
      type: Function,
    },
    updateList: {
      type: Function,
    },
    // 默认的数据格式
    form: {
      type: Object,
      default: () => {
        return { name: '' }
      },
    },
  },
  computed: {},
  watch: {
    // 监听值
    form: {
      handler(newVal, oldVal) {
        this.CategoryData = newVal
      },
      immediate: true,
    },
  },
  data() {
    return {
      openDialog: false,
      CategoryData: {
        name: '',
      },
      saveLoading: false,
    }
  },
  methods: {
    addCategory() {
      this.openDialog = true
    },
    cancel() {
      this.openDialog = false
      this.$refs.CategoryName.resetFields()
    }, 
    submit() {
      this.$refs.CategoryName.validate(async (valid) => {
        if (valid) {
          this.saveLoading = true
          this.getApi({
            id: 0,
            ...this.CategoryData,
          })
            .then((res) => {
              this.updateList(this.CategoryData)
              this.saveLoading = false
              this.$message.success('添加成功')
              this.openDialog = false
            }) 
            .catch((err) => {
              this.saveLoading = false
              // this.$message.error('添加失败')
            }) 
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div class="list-page">
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :thead="thead"
      :on-fetch="getList"
      :on-delete="handleDelete"
      :on-put-back="handlePutBack"
    >
      <template
        v-if="filterForm.tab !== 'checking'"
        #item_donation_status_text="{ row }"
      >
        <list-status-text :text="row.donation_status_text" />
      </template>
      <template v-slot:filter>
        <list-filter
          :filterForm="filterForm"
          :uploadFilter="refreshList"
        ></list-filter>
      </template>

      <template #top>
        <div style="margin-bottom: 20px">
          <el-button type="primary" size="small" @click="openPlaceDialog"
            >上传会场布置图</el-button
          >
        </div>
      </template>
      <template #tabletop>
        <!-- <el-button type="primary" size="small" @click="addUser"
          >单个添加</el-button
        > -->
        <el-button type="primary" size="small" @click="importAllUser"
          >批量编辑</el-button
        >
      </template>
      <template v-slot:operation="scope">
        <el-button type="text" size="small" @click="editRow(scope.row)"
          >编辑</el-button
        >
        <!-- <el-button type="text" size="small" @click="handleDelete(scope.row.id)"
          >删除</el-button
        > -->
      </template>
    </list-layout>
    <!-- 上传会场布置图弹窗 -->
    <place-dialog v-model="openPlace"></place-dialog>
    <!-- 批量导入弹窗 -->
    <ImportPlace
      v-model="openImportUser"
      :updateList="refreshList"
    ></ImportPlace>
    <!-- 添加修改用户弹窗 -->
    <SetUserDialog
      v-model="openSetUser"
      :currentItem="currentItem"
      @update="refreshList"
    ></SetUserDialog>
  </div>
</template>

<script>
import ListStatusText from "@/base/components/List/ListStatusText";
import ListLayout from "@/base/layout/ListLayout";
import ListFilter from "@/modules/activity/components/activity-ranking/ListFilter";
import PlaceDialog from "@/modules/activity/components/activity-ranking/PlaceDialog";
import ImportPlace from "@/modules/activity/components/activity-ranking/ImportPlace";
import SetUserDialog from "@/modules/activity/components/activity-ranking/SetUserDialog";

import {
  getActivityPlaceList,
  save,
  deleteRow,
  placeList,
  savePlace,
  delPlace,
  exportInviteUser,
  placeImportXlsx,
  placeImport,
} from "../../api/activity-ranking/list";
export default {
  components: {
    ListFilter,
    ListLayout,
    ListStatusText,
    PlaceDialog,
    ImportPlace,
    SetUserDialog,
  },
  data() {
    return {
      thead: [
        { type: "selection" },
        { label: "用户名称", prop: "user_name", minWidth: 100 },
        { label: "会内职务", prop: "title_name", minWidth: 200 },
        {
          label: "手机号码",
          prop: "user_phone",
          minWidth: 120,
        },
        {
          label: "座位号",
          prop: "table_number",
          minWidth: 120,
        },
        {
          label: "用餐桌位号",
          prop: "seat_number",
          minWidth: 120,
        },
        {
          label: "更多座位备注",
          prop: "seat_remarks",
          minWidth: 120,
        },
        {
          label: "会场地址",
          prop: "place_address",
          minWidth: 200,
        },
        {
          label: "所属会场",
          prop: "place_name",
          minWidth: 200,
        },
        { label: "操作", type: "operation", width: 100 },
      ],
      // 筛选对象
      filterForm: {
        activity_id: this.$route.params.id, // 活动id
        name: "",
        positionIds: [], // 会内职务
        phone: "", // 手机号
        seat_number: "", // 座位号
        table_number: "", // 用餐座位号
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        page_size: 15, //每页多少条数据
      },
      // 上传会场布置图弹窗
      openPlace: false,
      // 批量导入弹窗
      openImportUser: false,
      // 添加，修改用户弹窗
      openSetUser: false,
      currentItem: {},
    };
  },
  methods: {
    getList(data) {
      return getActivityPlaceList(data);
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.$refs.listLayout.getList(this.filterForm);
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList();
    },

    editRow(row) {
      console.log(row);
      let item = {
        ...row,
        name: row.user_name,
        phone: row.user_phone,
      };
      console.log(item);
      this.currentItem = item;
      this.openSetUser = true;
    },
    // 多删
    deletedArr() {
      const selectArr = this.$refs.listLayout.selectArr;
      if (selectArr.length > 0) {
        this.$msgbox
          .confirm("确定是否删除", "提示", {
            type: "error",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
          .then((res) => {
            deleteRow({ id: selectArr })
              .then((res) => {
                this.$message.success(res.msg);
                this.refreshList();
              })
              .catch((err) => {});
          })
          .catch((err) => console.log(err));
      } else {
        this.$message.info("没有检测到勾选数据");
      }
    },
    // 还原数据
    handlePutBack(data) {
      return putBacks({ id: data });
    },
    // 数据删除
    handleDelete(idList, forever = false) {
      deleteRow({ id: idList })
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    addUser() {
      this.currentItem = {
        id: 0,
        type: 0,
        name: "",
        phone: "",
        place_id: "",
        user_id: "",
        seat_number: "",
        table_number: "",
        seat_remarks: "",
      };
      this.openSetUser = true;
    },
    importAllUser() {
      this.openImportUser = true;
    },
    openPlaceDialog() {
      this.openPlace = true;
    },
  },

  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
};
</script>

<style lang="scss" scoped></style>
